<template>
	<w-dialog
		max-width="500px"
		:title="
			$t('customization.color_suggestion', {
				mode: $t(`customization.${theme}_mode`).toLowerCase()
			})
		"
		:value="!!(value && theme)"
		@input="$listeners.input"
	>
		<v-layout column>
			<w-text-info :text="$t('customization.color_picker_info')" />
			<v-flex></v-flex>
			<v-layout row wrap>
				<v-flex grow>
					<v-subheader v-text="$t('customization.primary_color') + ' :'" />
					<v-layout v-for="color in colors" :key="color" row wrap align-center justify-center>
						<v-flex shrink justify-center align-center>
							<v-checkbox v-model="primary" :color="color" :disabled="secondary === color" :value="color"></v-checkbox>
						</v-flex>
						<v-flex shrink justify-start align-center>
							<div :style="`background-color:${color};border-radius:10px;height:2.5em;width:2.5em`"></div>
						</v-flex>
					</v-layout>
				</v-flex>
				<v-divider vertical />
				<v-flex grow>
					<v-subheader v-text="$t('customization.secondary_color') + ' :'" />
					<v-layout v-for="color in colors" :key="color" row wrap align-center justify-center>
						<v-flex shrink justify-center align-center>
							<v-checkbox v-model="secondary" :color="color" :disabled="primary === color" :value="color"></v-checkbox>
						</v-flex>
						<v-flex shrink justify-start align-center>
							<div :style="`background-color:${color};border-radius:10px;height:2.5em;width:2.5em`"></div>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-layout>
		<template v-slot:actions>
			<v-layout justify-end>
				<w-btn-save :disabled="!canSave" :loading="loading" @click="save()" />
			</v-layout>
		</template>
	</w-dialog>
</template>

<script>
import CustomizationModuleGuard from '@/mixins/ModulesGuards/Customization/CustomizationModuleGuard'

export default {
	name: 'ColorSuggestionDialog',
	mixins: [CustomizationModuleGuard],
	props: {
		colors: {
			required: true,
			type: Array
		},
		theme: {
			default: '',
			required: false,
			type: String
		},
		value: {
			required: true,
			type: Boolean
		}
	},
	data: function () {
		return {
			canSave: false,
			loading: false,
			primary: null,
			secondary: null
		}
	},
	watch: {
		primary: {
			handler: 'computeCanSave'
		},
		secondary: {
			handler: 'computeCanSave'
		}
	},
	methods: {
		computeCanSave: function () {
			new Promise(resolve => {
				this.canSave = this.primary && this.secondary
				resolve()
			})
		},
		save: function () {
			this.loading = true
			new Promise(resolve => {
				let promise = this.service.updateWhiteLabel(this.accountingFirmId, this.theme, {
					primary_color: this.primary,
					secondary_color: this.secondary
				})
				resolve(promise)
			})
				.then(() => {
					this.$emit('input', false)
				})
				.finally(() => {
					this.loading = false
				})
		}
	}
}
</script>
